import React from 'react';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import TagManager from 'react-gtm-module';

class Tagging extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      siteLoaded: false,
    };
  }

  componentDidMount() {
    window.addEventListener('load', () => addObserversForProducts(this.props.sitecoreContext));

    // Load only
    let pageCategory = this.props.sitecoreContext.route.fields.pageCategoryGTM.value;
    if (this.props.sitecoreContext.route.templateName === 'BiolageProductRoute') pageCategory = 'product detail page';
    else if (this.props.sitecoreContext.route.templateName === 'ArticleRoute') pageCategory = 'content page::article';
    else if (this.props.sitecoreContext.route.templateName === 'BiolageProductFinderRoute') pageCategory = 'product selector page';

    if (!this.state.siteLoaded) {
      window.dataLayer.push({
        brand: 'BLG',
        language: 'it',
        country: 'IT',
        siteTypeLevel: 'main',
        pageCategory: pageCategory,
      });

      this.setState({ siteLoaded: true });
    }

    this.productDetailsPage();

    const tagManagerArgs = {
      gtmId: 'GTM-KWLJ7R6',
    };

    TagManager.initialize(tagManagerArgs);
  }

  componentWillUnmount() {
    window.removeEventListener('load', () => addObserversForProducts(this.props.sitecoreContext));
  }

  // ON PAGE CHANGE
  updateDataLayerPageview() {
    addObserversForProducts(this.props.sitecoreContext);
    this.productDetailsPage();

    let pageCategory = this.props.sitecoreContext.route.fields.pageCategoryGTM.value;
    if (this.props.sitecoreContext.route.templateName === 'BiolageProductRoute') pageCategory = 'product detail page';
    else if (this.props.sitecoreContext.route.templateName === 'ArticleRoute') pageCategory = 'content page::article';
    else if (this.props.sitecoreContext.route.templateName === 'BiolageProductFinderRoute') pageCategory = 'product selector page';

    window.dataLayer = window.dataLayer || [];
    var json = {
      event: 'updatevirtualpath',
      pageCategory: pageCategory,
      virtualPageUrl: window.location.pathname,
      virtualPageTitle: this.props.sitecoreContext.route.displayName,
    };
    window.dataLayer.push(json);
  }

  // PRODUCT DETAILS
  productDetailsPage() {
    if (this.props.sitecoreContext.route.templateName === 'BiolageProductRoute') {
      const name = this.props.sitecoreContext.route.displayName;
      const id = this.props.sitecoreContext.route.itemId;
      const category = window.location.pathname.split('/')[2];

      window.dataLayer.push({
        event: 'nievent',
        eventCategory: 'Ecommerce',
        eventAction: 'Product Detail',
        eventLabel: `${name}::${id}`,
        event_name: 'view_item',
        ecommerce: {
          detail: {
            products: [
              {
                name: name,
                id: id,
                brand: 'BLG',
                category: category,
              },
            ],
          },
        },
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    Object.entries(this.props).forEach(([key, val]) => {
      if (prevProps[key] !== val) {
        if (key == 'pageTitle') {
          this.updateDataLayerPageview();
        }
      }
    });
  }

  render() {
    return <div></div>;
  }
}

export default withSitecoreContext()(Tagging);

export function observeProductFinder(context) {
  addObserversForProducts(context);
}

// PRODUCT CLICK
export function productClickTagging(element) {
  const name = element.currentTarget.getAttribute('data-gtm-name');
  const id = element.currentTarget.getAttribute('data-gtm-id');
  const category = element.currentTarget.getAttribute('data-gtm-category');
  const list = element.currentTarget.getAttribute('data-gtm-list');
  const position = parseFloat(element.currentTarget.getAttribute('data-gtm-position'));

  window.dataLayer.push({
    event: 'productClick',
    eventCategory: 'Ecommerce',
    eventAction: 'Product Click',
    eventLabel: `${name}::${id}`,
    event_name: 'select_item',
    ecommerce: {
      click: {
        actionField: [
          {
            list: list,
          },
        ],
        products: [
          {
            name: name,
            brand: 'BLG',
            category: category,
            id: id,
            list: list,
            position: position,
          },
        ],
      },
    },
  });
}

// CLICK ON BUY BUTTON
export function handleClickToBuy(name, id) {
  window.dataLayer.push({
    event: 'uaevent',
    ecommerce: 'undefined',
    eventCategory: 'Ecommerce',
    eventAction: 'click to buy',
    eventLabel: `${name}::${id}`,
    event_name: 'buy_now',
    product_info: `${name}::${id}`,
  });
}

export function handleAddToCart(name, id, storeName, category) {
  window.dataLayer.push({
    event: 'addToCart',
    event_name: 'add_to_cart',
    eventAction: `redirection::${storeName}`,
    eventCategory: 'Ecommerce',
    eventLabel: `${name}::${id}`,
    product_info: `${name}::${id}`,
    step: 'redirection',
    store_name: storeName,

    ecommerce: {
      add: {
        products: [
          {
            brand: 'BLG',
            category: category,
            id: id,
            name: name,
          },
        ],
      },
    },
  });
}

// WRITE A REVIEW (BUTTON)
export function handleWriteReview(name, id) {
  window.dataLayer.push({
    event: 'uaevent',
    event_name: 'write_review',
    ecommerce: 'undefined',
    eventCategory: 'product detail page',
    eventAction: 'write review',
    eventLabel: `${name}::${id}`,
    produt_info: `${name}::${id}`,
  });
}

// SUBMIT A REVIEW
export function handleSubmitReview(name, id) {
  window.dataLayer.push({
    event: 'uaevent',
    event_name: 'submit_review',
    ecommerce: 'undefined',
    eventCategory: 'product detail page',
    eventAction: 'submit review',
    eventLabel: `${name}::${id}`,
    produt_info: `${name}::${id}`,
  });
}

// CLICK ON FILTERS
export function handleFilterClick(filtername, filtervalue) {
  window.dataLayer.push({
    event: 'uaevent',
    event_name: 'use_filters',
    ecommerce: 'undefined',
    eventCategory: 'product selector page',
    eventAction: 'filter',
    eventLabel: filtername,
    filter_type: filtervalue,
    filter_value: filtervalue,
  });
}

// INTERNAL SEARCH
export function handleInternalSearch(keyword) {
  window.dataLayer.push({
    event: 'uaevent',
    ecommerce: 'undefined',
    event_name: 'internal_search',
    eventCategory: 'internal search',
    eventAction: 'search::manual',
    eventLabel: keyword,
    search_keyword: keyword,
    method: 'manual',
  });
}

// STORELOCATOR SEARCH
export function handleStoreLocatorSearch(keyword) {
  window.dataLayer.push({
    event: 'uaevent',
    event_name: 'salon_locator',
    eventAction: 'find a store',
    eventCategory: 'salon_locator',
    eventLabel: keyword,
    search_keyword: keyword,
  });
}

// STORELOCATOR ITINERARY
export function handleStoreLocatorItinerary(storeName) {
  window.dataLayer.push({
    event: 'uaevent',
    event_name: 'itinerary',
    eventAction: 'itinerary',
    eventCategory: 'store locator',
    eventLabel: storeName,
    store_name: storeName,
  });
}

// NAVIGATION MENUS
export function handleNavigationClick(area, label) {
  window.dataLayer.push({
    event: 'uaevent',
    event_name: 'menu_click',
    ecommerce: 'undefined',
    eventCategory: 'main menu navigation',
    eventAction: 'select::' + area,
    eventLabel: label,
    click_area: area,
    breadcrumb: label,
  });
}

function addObserversForProducts(context) {
  // PRODUCT IMPRESSIONS
  setTimeout(function () {
    document.querySelectorAll('.product_impression').forEach(function (elem) {
      let observer = new IntersectionObserver(
        function (entries) {
          if (entries[0].isIntersecting === true) {
            const name = entries[0].target.getAttribute('data-gtm-name');
            const id = entries[0].target.getAttribute('data-gtm-id');
            const productCategory = entries[0].target.getAttribute('data-gtm-category');
            const list = entries[0].target.getAttribute('data-gtm-list');
            const position = parseFloat(entries[0].target.getAttribute('data-gtm-position'));

            let pageCategory = context.route.fields.pageCategoryGTM.value;
            if (context.route.templateName === 'BiolageProductRoute') pageCategory = 'product detail page';
            else if (context.route.templateName === 'ArticleRoute') pageCategory = 'content page::article';
            else if (context.route.templateName === 'BiolageProductFinderRoute') pageCategory = 'product selector page';

            window.dataLayer.push({
              event: 'nievent',
              eventCategory: 'Ecommerce',
              eventAction: 'Product Impressions',
              eventLabel: pageCategory,
              event_name: 'view_item_list',
              ecommerce: {
                currencyCode: 'EUR',
                impressions: [
                  {
                    name: name,
                    id: id,
                    brand: 'BLG',
                    category: productCategory,
                    list: list,
                    position: position,
                  },
                ],
              },
            });

            observer.unobserve(entries[0].target);
          }
        },
        { threshold: [0.1] }
      );

      observer.observe(elem);
    });
  }, 3500);
}
